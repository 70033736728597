@import 'styles/layers.scss';

@layer component {
  .content {
    max-width: 679px;
  }
  
  .title {
    margin: 20px 0 10px;
  }
  
  .billets {
    row-gap: 20px;
  }
  
  .descr {
    margin-bottom: 15px;
  }
}